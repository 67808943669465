import { ENV } from 'config'
import { ENV_PRODUCTION } from '../constants'

declare global {
	interface Window {
		_hsq: any
	}
}

// TODO: migrate to GTM
export const trackPageOnHubspot = (pageUrl: string) => {
	if (ENV !== ENV_PRODUCTION) {
		return
	}
	const _hsq = (window._hsq = window._hsq || [])
	_hsq.push(['setPath', pageUrl])
	_hsq.push(['trackPageView'])
}

export const trackAdClick = (userEmail: string, eventName: string, properties: object) => {
	const _dataLayer = (window.dataLayer = window.dataLayer || [])
	_dataLayer.push({
		event: 'ad_click',
		userEmail: userEmail,
		hubspotEventName: eventName,
		hubspotCustomEventProperties: properties
	})
}
