import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
	Box,
	Card,
	CardContent,
	Container,
	Divider,
	Grid2 as Grid,
	Link,
	Typography
} from '@mui/material'
import { LoginFirebase } from '../../components/authentication/login'
import { ScratchLogo } from 'components/common/ScratchLogo'

const Login = () => (
	<>
		<Helmet>
			<title>Login | Scratchpay Solutions</title>
		</Helmet>
		<Box
			sx={{
				backgroundColor: 'background.default',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh'
			}}
		>
			<Container maxWidth="sm" sx={{ py: '80px' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						mb: 2
					}}
				>
					<RouterLink to="/">
						<ScratchLogo size={120} />
					</RouterLink>
				</Box>
				<Card>
					<CardContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							p: 4
						}}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'space-between'
							}}
						>
							<div>
								<Typography color="textPrimary" gutterBottom variant="h4">
									Welcome to Scratchpay Platform
								</Typography>
								<Typography color="textSecondary" variant="body2">
									{}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									Welcome to Simpler Payments and Communications
								</Typography>
							</div>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								mt: 3
							}}
						>
							<LoginFirebase />
						</Box>
						<Divider sx={{ my: 3 }} />
						<Grid container rowSpacing={1}>
							<Grid size={12}>
								<Link
									color="textSecondary"
									component={RouterLink}
									to="/authentication/password-reset"
									variant="body2"
								>
									Forgot Password?
								</Link>
							</Grid>
							<Grid size={12}>
								{/* Temporarily disabled until end of July 2022 https://scratchpay.atlassian.net/browse/DASH-658 */}
								{/* <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/authentication/register"
                  variant="body2"
                >
                  Creating an Account?
                </Link> */}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Container>
		</Box>
	</>
)

export default Login
