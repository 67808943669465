import {
	CONSOLIDATOR_ID_BRM,
	CONSOLIDATOR_ID_VETCORE,
	CONSOLIDATOR_ID_THRIVE,
	COUNTRY_CA,
	CONSOLIDATOR_ID_RAREBREED,
	CONSOLIDATOR_ID_MVP,
	COUNTRY_US,
	PARTNER_ID_BRAVA
} from '../constants'
import { PAYMENT_BASE_URL } from '../config'
import { Practice } from 'gql/generated-types/custom-graphql'

export function isCheckOnlyPractice(currentPracticeObject: IPractice) {
	return (
		(currentPracticeObject as any)?.checkOnly === 1 || currentPracticeObject?.settings?.checkOnly
	)
}

export const isBravaPractice = (practice: IPractice) =>
	practice?.partnerIds?.includes(PARTNER_ID_BRAVA) ?? false

export const isVetCorePractice = (practice: IPractice) =>
	practice?.consolidatorId === CONSOLIDATOR_ID_VETCORE

export const isMVPPractice = (practice: IPractice) =>
	practice?.consolidatorId === CONSOLIDATOR_ID_MVP

export const isVetspirePIMSPractice = (practice: IPractice) =>
	practice?.currentPims?.toLowerCase() === 'vetspire'

export const isThrivePractice = (practice: IPractice) =>
	practice?.consolidatorId === CONSOLIDATOR_ID_THRIVE

export const isCanadianPractice = (practice: IPractice) =>
	practice?.clinicCountry?.toUpperCase() === COUNTRY_CA

export const isUSPractice = (practice: IPractice) =>
	practice?.clinicCountry?.toUpperCase() === COUNTRY_US

export const shouldShowInstallmentPlansCA = (practice: IPractice) =>
	practice?.settings?.isInstallmentPlansEnabled && isCanadianPractice(practice)

export const shouldShowInstallmentPlansUS = (practice: IPractice) =>
	practice?.settings?.isInstallmentPlansEnabled && isUSPractice(practice)

export const shouldShowInstallmentPlans = (practice: IPractice) =>
	practice?.settings?.isInstallmentPlansEnabled

export const shouldShowStoredCards = (practice: IPractice) =>
	practice?.settings?.isSavePaymentMethodsEnabled

export const shouldShowAppointmentRequests = (practice: IPractice) =>
	practice?.settings?.isAppointmentRequestsEnabled

export const shouldShowPaymentHistoryV2 = (practice: IPractice) =>
	practice?.settings?.usePaymentHistoryV2

export const practiceShouldShowDailyTotalsV2 = (practice: IPractice) =>
	getSettingByName(practice, 'useDailyTotalsV2')

export const shouldShowPaymentRequestsPage = (practice: IPractice) =>
	getSettingByName(practice, 'showPaymentRequestsPage')

export const shouldShowPriorityCallList = (practice: IPractice) =>
	practice?.settings?.isPriorityCallListEnabled

export const shouldShowReputationManagement = (practice: IPractice) =>
	practice?.settings?.isReputationEnabled

export const shouldShowOptOuts = (practice: IPractice) => practice?.settings?.isOptOutsEnabled

export const shouldShowForms = (practice: IPractice) => practice?.settings?.isFormsEnabled

// Communications Section in sidebar should show if any of the communication settings are enabled
export const shouldShowCommunicationsSection = (practice: IPractice) =>
	practice?.isChatEnabled === true ||
	practice?.settings?.isPriorityCallListEnabled === true ||
	practice?.settings?.isAppointmentRequestsEnabled === true ||
	practice?.settings?.isRemindersEnabled === true ||
	practice?.settings?.isOneTimeEmailsEnabled === true ||
	practice?.settings?.isReputationEnabled === true

export const shouldShowECheckReportingPage = (practice: IPractice) =>
	practice?.settings?.showECheckReportingPage ?? false

export const shouldShowManualPayment = (practice: IPractice) =>
	practice?.settings?.manualPaymentEnabled ?? true

export const isVetCorAdmin = (user) =>
	user && user.isAdmin && user.consolidatorId === CONSOLIDATOR_ID_VETCORE

export const isMVPAdmin = (user) =>
	user && user.isAdmin && user.consolidatorId === CONSOLIDATOR_ID_MVP

export const isRareBreedPractice = (practice: IPractice) =>
	practice?.consolidatorId === CONSOLIDATOR_ID_RAREBREED

export const isSurchargeEnabled = (practice: IPractice) =>
	(practice?.settings as any)?.surchargeEnabled || practice?.surchargeEnabled

export const shouldShowCheckPayments = (
	practice: IPractice,
	consolidatorHasCheckEnabled?: boolean
) => practice?.settings?.isCheckEnabled ?? consolidatorHasCheckEnabled ?? false

export const shouldShowMonthlyStatements = (practice: IPractice) => {
	const consolidatorId = practice?.consolidatorId ?? null
	if (!consolidatorId) return true
	if (
		[
			CONSOLIDATOR_ID_MVP,
			CONSOLIDATOR_ID_BRM,
			CONSOLIDATOR_ID_VETCORE,
			CONSOLIDATOR_ID_THRIVE,
			CONSOLIDATOR_ID_RAREBREED
		].includes(consolidatorId)
	)
		return false
	return true
}

export const generatePracticePaymentLink = (practiceName, webPaymentPracticeId) =>
	`${PAYMENT_BASE_URL}/w/${practiceName.replace(/\s+/g, '-')}/${webPaymentPracticeId}`

export const shouldShowPaymentRequestRemindersFooterLink = (practice: IPractice) =>
	isVetCorePractice(practice) && !practice?.flags?.paymentRequestReminders

/**
 * Checks whether a specific feature is enabled based on the provided setting name and practice object.
 * Returns false ONLY if the settingName is explicitly defined in the practice settings object with a value of false.
 * Returns true if the settingName is not defined in the practice settings object or if its value is truthy.
 */
export const isFeatureEnabled = (practice, settingName) =>
	(practice?.settings?.[settingName] ?? true) && practice?.settings?.[settingName] !== false

export const getSettingByName = (practice: IPractice | undefined, settingName: string) =>
	practice?.settings?.[settingName] ?? false

export const shouldAllowSavePaymentMethod = (practice: IPractice) =>
	getSettingByName(practice, 'isSavePaymentMethodsEnabled') === true

export const shouldShowCustomPayoutsV2 = (practice: IPractice) => {
	if (!practice) return false
	return practice.settings?.['useCustomPayoutsV2'] === true
}

export const shouldShowMavenChat = (practice?: IPractice) => practice?.settings?.mavenBetaEnabled

export const getPracticeCreatedAtTimestamp = (currentPracticeObject) => {
	const createdAt = currentPracticeObject?.createdAt
	if (typeof createdAt === 'number') {
		return currentPracticeObject?.createdAt
	}
	if (typeof createdAt === 'string') {
		try {
			const date = new Date(createdAt)
			return Math.floor(date.getTime() / 1000)
		} catch {
			console.error('Could not parse createdAt')
			return 0
		}
	}
	return 0
}

export const isFinancingEnabled = (practice: IPractice | Practice) => !!practice?.vetClinicId
export const isPaymentsEnabled = (practice: IPractice | Practice) => !!practice?.stripeAccountId
export const isFinancingOnlyPractice = (practice: IPractice | Practice) =>
	isFinancingEnabled(practice) && !isPaymentsEnabled(practice)

export const blockAmexPayments = (practice: IPractice) =>
	getSettingByName(practice, 'blockAmex') === true

export const isIndependentPractice = (practice: IPractice) => !practice.consolidatorId

export const isServerDrivenTerminalEnabled = (practice: IPractice) =>
	practice?.settings?.localTerminalConnection !== true
