import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { getMavenUserToken } from 'apis/user'
import { selectUser } from 'selectors/auth'
import { useCurrentPractice } from 'contexts/PracticeContext'
import { MAVEN_AGI_CHAT_CONFIG } from '../../config'

declare global {
	interface Window {
		Maven: any
	}
}

export const MavenChatWidget = () => {
	const user = useSelector(selectUser)
	const { currentPracticeObject } = useCurrentPractice()

	const isInitializing = useRef(false)
	const [isChatInitialized, setIsChatInitialized] = useState(false)

	const initChatWidget = async () => {
		if (!user || isEmpty(currentPracticeObject)) {
			return
		}

		if (isChatInitialized || isInitializing.current) {
			return
		}

		if (window?.Maven?.ChatWidget) {
			isInitializing.current = true

			const { data } = await getMavenUserToken(
				(currentPracticeObject.dbid ||
					currentPracticeObject.id ||
					currentPracticeObject.stripeAccountId) as string
			)

			if (!data?.token) {
				setIsChatInitialized(false)
				isInitializing.current = false
				return
			}

			// TODO: migrate to GTM once integration succeeds
			window.Maven.ChatWidget.load({
				textColor: 'white',
				bgColor: '#5B70FF',
				organizationId: MAVEN_AGI_CHAT_CONFIG.organizationId,
				agentId: MAVEN_AGI_CHAT_CONFIG.agentId,
				horizontalPosition: 'right',
				verticalPosition: 'bottom',
				signedUserData: data.token
			}).then(() => {
				setIsChatInitialized(true)
				isInitializing.current = false
			})
		}
	}

	const closeChatWidget = () => {
		if (!isInitializing.current) {
			setIsChatInitialized(false)
			window?.Maven?.ChatWidget?.close()
			const chatElements = window.document.querySelectorAll('#maven-chat-widget')
			chatElements.forEach((element) => element.remove())
		}
	}

	useEffect(() => {
		initChatWidget()
		return () => closeChatWidget()
	}, [user, currentPracticeObject])

	return null
}
