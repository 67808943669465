import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useCurrentPractice } from 'contexts/PracticeContext'
import { useFlags } from 'hooks/useFlags'
import { GetAdsContent, getAdsContent } from 'util/contentful'
import { trackAdClick } from 'util/hubspot'
import { selectUser } from 'selectors/auth'

type SidebarAdContent = Omit<GetAdsContent, 'image'> & { image: string }

const SidebarAds = () => {
	const user = useSelector(selectUser)
	const { currentPracticeObject, userRolePractice } = useCurrentPractice()
	const { flags } = useFlags(currentPracticeObject, userRolePractice)

	const [selectedAd, setSelectedAd] = useState<SidebarAdContent | null>(null)

	const handleAdClick = () => {
		if (!user?.email || !selectedAd?.metadata?.hubspotEventName) {
			return
		}

		trackAdClick(user.email, selectedAd?.metadata?.hubspotEventName, {
			partner_id: currentPracticeObject?.vetClinicId
		})
	}

	useEffect(() => {
		const storedAd = sessionStorage.getItem('selectedAd')

		if (storedAd) {
			setSelectedAd(JSON.parse(storedAd))
			return
		}

		const fetchAds = async () => {
			try {
				const content = await getAdsContent(flags)
				const details: SidebarAdContent[] = content.map((item) => ({
					title: item.title,
					image: `https:${item?.image?.fields?.file?.url}`,
					link: item.link,
					order: item.order,
					flag: item.flag,
					metadata: item.metadata
				}))

				if (details.length > 0) {
					const randomAd = details[Math.floor(Math.random() * details.length)]
					sessionStorage.setItem('selectedAd', JSON.stringify(randomAd))
					setSelectedAd(randomAd)
				}
			} catch (err) {
				console.error('Failed to fetch ads:', err)
			}
		}

		fetchAds()
	}, [currentPracticeObject])

	if (!selectedAd) return null

	return (
		<a href={selectedAd.link} target="_blank" rel="noopener noreferrer" onClick={handleAdClick}>
			<img
				height={200}
				width={210}
				alt={selectedAd.title || 'Ad'}
				src={selectedAd.image}
				style={{ borderRadius: '12px' }}
			/>
		</a>
	)
}

export default SidebarAds
