type Flags = {
	[key: string]: boolean
}

interface UseFlags {
	canViewBankAccountInfo: boolean
	canViewMonthlyStatement: boolean
	canViewIntegrationToken: boolean
	canViewProviderAgreement: boolean
	canViewMarketingMaterials: boolean
	flags: Flags
}

export const useFlags = (
	currentPracticeObject: IPractice,
	userRolePractice: UserRolePractice | undefined
): UseFlags => {
	const canViewBankAccountInfo = (() => {
		const isStaff = userRolePractice?.staff || false
		const isAchOffPractice = currentPracticeObject?.originationFlags?.achOff || false
		const isAchOffConsolidator =
			currentPracticeObject?.consolidator?.financingFlags?.achOff || false

		return !(isStaff || isAchOffPractice || isAchOffConsolidator)
	})()

	const canViewMonthlyStatement = (() => {
		const isHideMonthlyStatementPractice =
			currentPracticeObject?.originationFlags?.hideMonthlyStatement || false
		const isHideMonthlyStatementConsolidator =
			currentPracticeObject?.consolidator?.financingFlags?.hideMonthlyStatement || false

		return !(isHideMonthlyStatementPractice || isHideMonthlyStatementConsolidator)
	})()

	const canViewIntegrationToken = (() => {
		const isHideIntegrationTokenPractice =
			currentPracticeObject?.originationFlags?.hideToken || false
		const isHideIntegrationTokenConsolidator =
			currentPracticeObject?.consolidator?.financingFlags?.hideToken || false

		return !(isHideIntegrationTokenPractice || isHideIntegrationTokenConsolidator)
	})()

	const canViewProviderAgreement = (() => {
		const isHideProviderAgreementPractice =
			currentPracticeObject?.originationFlags?.hideProviderAgreement || false
		const isHideProviderAgreementConsolidator =
			currentPracticeObject?.consolidator?.financingFlags?.hideProviderAgreement || false

		return !(isHideProviderAgreementPractice || isHideProviderAgreementConsolidator)
	})()

	const canViewMarketingMaterials = (() => {
		const isHideMarketingMaterialsPractice =
			currentPracticeObject?.originationFlags?.hideMarketingNav || false
		const isHideMarketingMaterialsConsolidator =
			currentPracticeObject?.consolidator?.financingFlags?.hideMarketingNav || false

		return !(isHideMarketingMaterialsPractice || isHideMarketingMaterialsConsolidator)
	})()

	const flags: Flags = (() => ({
		...currentPracticeObject?.consolidator?.financingFlags,
		...currentPracticeObject?.originationFlags,
		achOff: !canViewBankAccountInfo,
		hideMonthlyStatement: !canViewMonthlyStatement,
		hideToken: !canViewIntegrationToken,
		hideProviderAgreement: !canViewProviderAgreement,
		hideMarketingNav: !canViewMarketingMaterials
	}))()

	return {
		canViewBankAccountInfo,
		canViewMonthlyStatement,
		canViewIntegrationToken,
		canViewProviderAgreement,
		canViewMarketingMaterials,
		flags
	}
}
