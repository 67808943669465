import React from 'react'
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Typography,
	Button,
	Theme,
	Grid2 as Grid
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import NavSection from './NavSection'
import { NavItem } from './dashboard/DashboardSidebar'
import { CustomBadge } from './common/CustomBadge'

interface AccordionSectionProps {
	title: string
	items?: NavItem[]
	pathname?: string
	expanded?: boolean
	onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void
	signupLink?: string
	badgeCount?: number
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
	title,
	items,
	pathname,
	expanded,
	onChange,
	signupLink,
	badgeCount
}) => {
	if (signupLink) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '8px 16px',
					'&:hover': {
						backgroundColor: 'action.hover'
					}
				}}
			>
				<Typography
					component={RouterLink}
					to={signupLink}
					sx={(theme: Theme) => ({
						fontSize: '.875rem',
						color: expanded ? theme.palette.primary.main : theme.palette.text.secondary,
						textDecoration: 'none',
						'&:hover': {
							color: theme.palette.primary.main
						}
					})}
				>
					{title}
				</Typography>
				<Button
					component={RouterLink}
					to={signupLink}
					variant="outlined"
					size="small"
					sx={{
						borderRadius: '5px !important',
						cursor: 'pointer',
						width: 75,
						height: 25,
						fontWeight: 600
					}}
				>
					Sign up
				</Button>
			</Box>
		)
	}

	if (items && items.length > 0) {
		return (
			<Accordion
				expanded={expanded}
				onChange={onChange}
				sx={{
					background: 'transparent',
					boxShadow: 'none',
					'&:before': {
						display: 'none'
					},
					'&.Mui-expanded': {
						margin: 0
					},
					'&:hover': {
						backgroundColor: (theme) => (expanded ? 'transparent' : theme.palette.action.hover)
					},
					width: '100%'
				}}
			>
				<AccordionSummary
					aria-controls={`${title}-content`}
					id={`${title}-header`}
					sx={{
						padding: '4px 16px',
						minHeight: 'auto',
						'&.Mui-expanded': {
							minHeight: 'auto'
						},
						'& .MuiAccordionSummary-content': {
							margin: '8px 0',
							display: 'flex',
							alignItems: 'center',
							'&.Mui-expanded': {
								margin: '8px 0'
							}
						},
						'&:hover': {
							cursor: 'pointer'
						},
						'& .MuiAccordionSummary-expandIconWrapper': {
							marginLeft: 'auto'
						}
					}}
				>
					<Grid container alignItems="center">
						<Grid
							sx={{
								flexGrow: 1,
								display: 'flex',
								alignItems: 'center'
							}}
						>
							<Typography
								sx={(theme: Theme) => ({
									fontWeight: 400,
									fontSize: '.875rem',
									color: theme.palette.text.secondary,
									'&.Mui-expanded': {
										color: theme.palette.primary.main
									},
									mr: 4
								})}
								className={expanded ? 'Mui-expanded' : ''}
							>
								{title}
							</Typography>
							{!expanded && typeof badgeCount === 'number' && badgeCount > 0 && (
								<CustomBadge badgeContent={badgeCount} />
							)}
						</Grid>
						<Box sx={{ flexGrow: 1 }} />
					</Grid>
				</AccordionSummary>
				<AccordionDetails
					sx={(theme: Theme) => ({
						padding: '8px 0 0 0',
						marginLeft: '16px',
						marginBottom: 1,
						background: theme.palette.mode === 'dark' ? '#2F3344' : '#fff',
						borderRadius: 2
					})}
				>
					<NavSection
						items={items}
						pathname={pathname ?? ''}
						title=""
						sx={{
							'& + &': {
								mt: 3
							},
							'& .MuiListItem-root': {
								borderRadius: 1,
								px: 1
							}
						}}
					/>
				</AccordionDetails>
			</Accordion>
		)
	}
	// Do not expand if there are no items in section (note: used for sign up sections)
	return null
}

export default AccordionSection
