import { lazy } from 'react'
import AuthGuard from './components/AuthGuard'
import DashboardLayout from './components/dashboard/DashboardLayout'
import GuestGuard from './components/GuestGuard'
import NoPracticeGuard from './components/NoPracticeGuard'
import FinancingDevGuard from './components/FinancingDevGuard'
import Loadable from './components/Loadable'
import { lazyWithRetry } from 'util/lazy-with-retry'

// Authentication pages
const Login = Loadable(lazyWithRetry(() => import('./pages/authentication/Login')))
const PasswordReset = Loadable(lazyWithRetry(() => import('./pages/authentication/PasswordReset')))
const Register = Loadable(lazyWithRetry(() => import('./pages/authentication/Register')))

// Dashboard pages
const GettingStarted = Loadable(lazyWithRetry(() => import('./pages/dashboard/GettingStarted')))
const Account = Loadable(lazyWithRetry(() => import('./pages/dashboard/Account')))
const PracticeRequiredInfo = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PracticeRequiredInfo'))
)

const TerminalPayment = Loadable(lazyWithRetry(() => import('./pages/dashboard/TerminalPayment')))
const TextToPay = Loadable(lazyWithRetry(() => import('./pages/dashboard/TextToPay')))
const FAQs = Loadable(lazyWithRetry(() => import('./pages/dashboard/FAQs')))
const Users = Loadable(lazyWithRetry(() => import('./pages/dashboard/Users/Users')))
const UserDetails = Loadable(lazyWithRetry(() => import('./pages/dashboard/Users/UserDetails')))
const Practices = Loadable(lazyWithRetry(() => import('./pages/dashboard/Practices')))
const PracticesV2 = Loadable(lazyWithRetry(() => import('./pages/dashboard/PracticesGQL')))
const PracticeDetails = Loadable(lazyWithRetry(() => import('./pages/dashboard/PracticeDetails')))
const PracticeDetailsV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PracticeDetailsGQL'))
)
const PracticeEdit = Loadable(lazyWithRetry(() => import('./pages/dashboard/PracticeEdit')))
const PayoutReporting = Loadable(lazyWithRetry(() => import('./pages/dashboard/PayoutReporting')))
const PayoutReportingV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PayoutReportingV2/PayoutReportingV2'))
)
const PayoutReportingEnterprise = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PayoutReportingEnterprise'))
)
const PayoutReportingEnterpriseV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PayoutReportingV2/PayoutReportingEnterpriseV2'))
)
const CheckReporting = Loadable(lazyWithRetry(() => import('./pages/dashboard/CheckReporting')))
const ECheckLockboxEnterpriseReport = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/ECheckLockboxEnterpriseReport'))
)
const ECheckLockboxEnterpriseReportV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/ECheckLockboxEnterpriseReportV2'))
)
const Products = Loadable(lazyWithRetry(() => import('./pages/dashboard/Products')))
const ProductInventory = Loadable(lazyWithRetry(() => import('./pages/dashboard/ProductInventory')))
const ProductSummary = Loadable(lazyWithRetry(() => import('./pages/dashboard/ProductSummary')))
const ProductAnalytics = Loadable(lazyWithRetry(() => import('./pages/dashboard/ProductAnalytics')))

const Payments = Loadable(lazyWithRetry(() => import('./pages/dashboard/Payments/Payments')))
const PaymentsV2 = Loadable(lazyWithRetry(() => import('./pages/dashboard/Payments/Payments-v2')))
const PaymentsSignup = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/Payments/PaymentsSignup'))
)
const AllPayments = Loadable(lazyWithRetry(() => import('./pages/dashboard/AllPayments')))
const PaymentIntents = Loadable(lazyWithRetry(() => import('./pages/dashboard/PaymentIntents')))
const AllPaymentRequests = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PaymentRequests/AllPaymentRequests'))
)
const PaymentRequests = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PaymentRequests/PaymentRequests'))
)
const PaymentDetails = Loadable(lazyWithRetry(() => import('./pages/dashboard/PaymentDetails')))
const TransactionSummary = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/TransactionSummary'))
)
const Refunds = Loadable(lazyWithRetry(() => import('./pages/dashboard/Refunds')))
const RefundRequest = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/RefundRequest/RefundRequestReporting'))
)
const RepresentativeInfo = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/RepresentativeInfo'))
)
const Disputes = Loadable(lazyWithRetry(() => import('./pages/dashboard/Disputes')))
const DisputeDetails = Loadable(lazyWithRetry(() => import('./pages/dashboard/DisputeDetails')))
const AllDisputes = Loadable(lazyWithRetry(() => import('./pages/dashboard/AllDisputes')))
const AdminAllDisputes = Loadable(lazyWithRetry(() => import('./pages/dashboard/AdminAllDisputes')))
const AllPractices = Loadable(lazyWithRetry(() => import('./pages/dashboard/AllPractices')))
const Reconciliation = Loadable(lazyWithRetry(() => import('./pages/dashboard/Reconciliation')))
const CustomerDetails = Loadable(lazyWithRetry(() => import('./pages/dashboard/CustomerDetails')))
const CustomerEdit = Loadable(lazyWithRetry(() => import('./pages/dashboard/CustomerEdit')))
const CustomerList = Loadable(lazyWithRetry(() => import('./pages/dashboard/CustomerList')))
const InvoiceDetails = Loadable(lazyWithRetry(() => import('./pages/dashboard/InvoiceDetails')))
const PracticeInvoices = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/Invoices/PracticeInvoices'))
)
const ConsolidatorInvoices = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/Invoices/ConsolidatorInvoices'))
)
const AR = Loadable(lazyWithRetry(() => import('./pages/dashboard/AR')))
const Chat = Loadable(lazyWithRetry(() => import('./pages/communication/Chat/Chat')))
const ChatSettings = Loadable(
	lazyWithRetry(() => import('./pages/communication/Chat/ChatSettings'))
)
const CustomFormTemplate = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/CustomFormTemplate'))
)
const Appointments = Loadable(lazyWithRetry(() => import('./pages/dashboard/Appointments')))

const AllAR = Loadable(lazyWithRetry(() => import('./pages/dashboard/AllAR')))
const DailyTotals = Loadable(lazyWithRetry(() => import('./pages/dashboard/DailyTotals')))
const DailyTotalsV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/DailyTotalsV2/DailyTotalsV2'))
)
const MonthlyStatementList = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/MonthlyStatementList'))
)
const MonthlyStatementDetail = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/MonthlyStatementDetail'))
)
const ConsDailyStatementList = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/ConsDailyStatementList'))
)
const HelpDesk = Loadable(lazyWithRetry(() => import('./pages/dashboard/HelpDesk')))

const ConsolidatorMonthlyReport = Loadable(
	lazyWithRetry(
		() => import('./pages/dashboard/consolidator-monthly-reports/ConsolidatorMonthlyReport')
	)
)

// Error pages
const AuthorizationRequired = Loadable(
	lazyWithRetry(() => import('./pages/errors/AuthorizationRequired'))
)
const NotFound = Loadable(lazyWithRetry(() => import('./pages/errors/NotFound')))
const ServerError = Loadable(lazyWithRetry(() => import('./pages/errors/ServerError')))

const BankingInfo = Loadable(lazyWithRetry(() => import('./pages/settings/BankingInfo')))
const RequiredInfo = Loadable(lazyWithRetry(() => import('./pages/settings/RequiredInfo')))

const DynamicForm = Loadable(lazyWithRetry(() => import('./pages/DynamicForm')))
const PracticeSignUp = Loadable(
	lazyWithRetry(() => import('./pages/authentication/signup/PracticeSignUp'))
)
const TerminalOrders = Loadable(lazyWithRetry(() => import('./pages/dashboard/TerminalOrders')))

const ReadersReporting = Loadable(lazyWithRetry(() => import('./pages/dashboard/ReadersReporting')))

const PostcardFiles = Loadable(lazyWithRetry(() => import('./pages/dashboard/PostcardFiles')))

const PostcardFileItems = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PostcardFileItems'))
)

const AccountInvite = Loadable(lazyWithRetry(() => import('./pages/dashboard/AccountsInvitations')))

const SpecialNames = Loadable(lazyWithRetry(() => import('./pages/dashboard/SpecialNames')))

const LockboxChecks = Loadable(lazyWithRetry(() => import('./pages/dashboard/LockboxChecks')))

const Reminders = Loadable(lazyWithRetry(() => import('./pages/communication/Reminders/Reminders')))

const InstallmentPlans = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/InstallmentPlans/InstallmentPlans'))
)

const PastDueInstallmentPlans = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PastDueInstallmentInvoices/PastDuePage'))
)

const OneTimeEmail = Loadable(
	lazyWithRetry(() => import('./pages/communication/OneTimeEmail/OneTimeEmail'))
)

const AppointmentRequests = Loadable(
	lazyWithRetry(() => import('./pages/communication/AppointmentRequests/AppointmentRequests'))
)

const Forms = Loadable(lazyWithRetry(() => import('./pages/communication/Forms/Forms')))

const PriorityCallList = Loadable(
	lazyWithRetry(() => import('./pages/communication/PriorityCallList/PriorityCallList'))
)

const ReputationManagement = Loadable(
	lazyWithRetry(() => import('./pages/communication/ReputationManagement/ReputationManagement'))
)

const OptOuts = Loadable(lazyWithRetry(() => import('./pages/communication/OptOuts/OptOuts')))

const StoredCards = Loadable(
	lazyWithRetry(() => import('./pages/reporting/StoredCards/StoredCards'))
)

const OptOutsPage = Loadable(lazyWithRetry(() => import('./pages/dashboard/optOuts/OptOutsPage')))

const ActionNeededV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/ActionNeededV2/ActionNeededV2'))
)

const EnterpriseOpenActionItemsV2 = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/ActionNeededV2/EnterpriseOpenActionItemsV2'))
)

const GetStarted = Loadable(lazy(() => import('./pages/financing/GetStarted')))
const Training = Loadable(lazy(() => import('./pages/financing/Training')))
const FinancingAccount = Loadable(lazy(() => import('./pages/financing/Account')))
const FinancingPlans = Loadable(lazy(() => import('./pages/financing/Plans')))

const CustomPayoutsPracticesList = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/CustomPayouts/PracticesListPage'))
)

const PracticeFeeSettingsList = Loadable(
	lazyWithRetry(() => import('./pages/dashboard/PracticeFeeSettings/PracticesListPage'))
)

const routes = [
	{
		path: 'authentication',
		children: [
			{
				path: 'login',
				element: (
					<GuestGuard>
						<Login />
					</GuestGuard>
				)
			},
			{
				path: 'login-unguarded',
				element: <Login />
			},
			{
				path: 'password-reset',
				element: (
					<GuestGuard>
						<PasswordReset />
					</GuestGuard>
				)
			},
			{
				path: 'register',
				element: (
					<GuestGuard>
						<Register />
					</GuestGuard>
				)
			},
			{
				path: 'register-unguarded',
				element: <Register />
			}
		]
	},
	{
		path: 'NoPracticeGuard',
		children: [
			{
				path: 'no-practice-found',
				element: (
					<NoPracticeGuard>
						<GettingStarted />
					</NoPracticeGuard>
				)
			},
			{
				path: 'password-reset',
				element: (
					<NoPracticeGuard>
						<PasswordReset />
					</NoPracticeGuard>
				)
			},
			{
				path: 'register',
				element: (
					<NoPracticeGuard>
						<Register />
					</NoPracticeGuard>
				)
			}
		]
	},
	{
		path: 'dashboard',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <GettingStarted />
			},
			{
				path: 'payments',
				children: [
					{
						path: '',
						element: <Payments />
					},
					{
						path: 'sign-up',
						element: <PaymentsSignup />
					}
				]
			},
			{
				path: 'payments/:channelFilterParam',
				element: <Payments />
			},
			{
				path: 'payments/:channelFilterParam/:dateParam',
				element: <Payments />
			},
			{
				path: 'payments/:channelFilterParam/:dateParam/:statusParam',
				element: <Payments />
			},
			{
				path: 'payments-v2',
				element: <PaymentsV2 />
			},
			{
				path: 'payments-v2/:channelFilterParam',
				element: <PaymentsV2 />
			},
			{
				path: 'payments-v2/:channelFilterParam/:dateParam',
				element: <PaymentsV2 />
			},
			{
				path: 'payments-v2/:channelFilterParam/:dateParam/:statusParam',
				element: <PaymentsV2 />
			},
			{
				path: 'paymentIntents',
				children: [
					{
						path: '',
						element: <PaymentIntents />
					},
					{
						path: ':paymentIntentID',
						element: <PaymentDetails />
					}
				]
			},
			{
				path: 'invoices-v2',
				element: <PaymentRequests />
			},
			{
				path: 'chat',
				children: [
					{
						path: '',
						element: <Chat />
					},
					{
						path: 'new',
						element: <Chat />
					},
					{
						path: ':threadKey',
						element: <Chat />
					},
					{
						path: 'settings',
						element: <ChatSettings />
					},
					{
						path: 'template',
						element: <CustomFormTemplate />,
						children: [
							{
								path: 'new',
								element: <CustomFormTemplate />
							},
							{
								path: ':templateId',
								element: <CustomFormTemplate />
							}
						]
					},
					{
						path: 'snippets',
						element: <CustomFormTemplate />,
						children: [
							{
								path: 'new',
								element: <CustomFormTemplate />
							},
							{
								path: ':snippetId',
								element: <CustomFormTemplate />
							}
						]
					}
				]
			},
			{
				path: 'appointments',
				element: <Appointments />
			},
			{
				path: 'paymentIntents',
				element: <PaymentIntents />
			},
			{
				path: 'refunds',
				element: <Refunds />
			},
			{
				path: 'refund-requests',
				element: <RefundRequest />
			},
			{
				path: 'representative-info',
				element: <RepresentativeInfo />
			},
			{
				path: 'disputes',
				children: [
					{
						path: '',
						element: <Disputes />
					},
					{
						path: ':disputeId',
						element: <DisputeDetails />
					}
				]
			},
			{
				path: 'checkReporting',
				children: [
					{
						path: '',
						element: <CheckReporting />
					}
				]
			},
			{
				path: 'echeck-lockbox-report',
				element: <ECheckLockboxEnterpriseReport />
			},
			{
				path: 'echeck-lockbox-report-v2',
				element: <ECheckLockboxEnterpriseReportV2 />
			},
			{
				path: 'allDisputes',
				element: <AllDisputes />
			},
			{
				path: 'all-disputes',
				element: <AdminAllDisputes />
			},
			{
				path: 'allPractices',
				element: <AllPractices />
			},
			{
				path: 'all-payments',
				element: <AllPayments />
			},
			{
				path: 'all-invoices-v2',
				element: <AllPaymentRequests />
			},
			{
				path: 'reconciliation',
				element: <Reconciliation />
			},
			{
				path: 'users',
				children: [
					{
						path: '',
						element: <Users />
					},
					{
						path: ':userID',
						element: <UserDetails />
					}
				]
			},
			{
				path: 'practices',
				children: [
					{
						path: '',
						element: <Practices />
					},
					{
						path: ':practiceID',
						element: <PracticeDetails />
					},
					{
						path: ':practiceID/edit',
						element: <PracticeEdit />
					}
				]
			},
			{
				path: 'practices-v2',
				children: [
					{
						path: '',
						element: <PracticesV2 />
					},
					{
						path: ':practiceID',
						element: <PracticeDetailsV2 />
					},
					{
						path: ':practiceID/edit',
						element: <PracticeEdit />
					}
				]
			},
			{
				path: 'payouts',
				children: [
					{
						path: '',
						element: <PayoutReporting />
					}
				]
			},
			{
				path: 'payouts-v2',
				children: [
					{
						path: '',
						element: <PayoutReportingV2 />
					}
				]
			},
			{
				path: 'allpayouts',
				children: [
					{
						path: '',
						element: <PayoutReportingEnterprise />
					}
				]
			},
			{
				path: 'allpayouts-v2',
				children: [
					{
						path: '',
						element: <PayoutReportingEnterpriseV2 />
					}
				]
			},
			{
				path: 'ActionNeeded',
				element: <ActionNeededV2 />
			},
			{
				path: 'open-action-items',
				element: <EnterpriseOpenActionItemsV2 />
			},
			{
				path: 'products',
				element: <Products />
			},
			{
				path: 'products/:tabParam',
				element: <Products />
			},
			{
				path: 'products/:tabParam/summary',
				element: <ProductSummary />
			},
			{
				path: 'products/:tabParam/analytics',
				element: <ProductAnalytics />
			},
			{
				path: 'products/:tabParam/inventory',
				element: <ProductInventory />
			},
			{
				path: 'account',
				element: <Account />
			},
			{
				path: 'account/practice-required-info',
				element: <PracticeRequiredInfo />
			},
			{
				path: 'account/:tabParam',
				element: <Account />
			},
			{
				path: 'collect-payment',
				element: <TerminalPayment />
			},
			{
				path: 'collect-payment/:tabParam',
				element: <TerminalPayment />
			},
			{
				path: 'text-to-pay',
				element: <TextToPay />
			},
			{
				path: 'ar',
				element: <AR />
			},
			{
				path: 'allAR',
				element: <AllAR />
			},
			{
				path: 'transaction-summary',
				element: <TransactionSummary />
			},
			{
				path: 'transaction-summary/:tabParam',
				element: <TransactionSummary />
			},
			{
				path: 'dailytotals',
				element: <DailyTotals />
			},
			{
				path: 'dailyTotals-v2',
				element: <DailyTotalsV2 />
			},
			{
				path: 'monthlytotals',
				element: <MonthlyStatementList />
			},
			{
				path: 'monthlytotal/:stmtId',
				element: <MonthlyStatementDetail />
			},
			{
				path: 'daily-feed',
				element: <ConsDailyStatementList />
			},
			{
				path: 'monthly-report',
				element: <ConsolidatorMonthlyReport />
			},
			{
				path: 'faq',
				element: <FAQs />
			},
			{
				path: 'customers',
				children: [
					{
						path: '',
						element: <CustomerList />
					},
					{
						path: ':customerId',
						element: <CustomerDetails />
					},
					{
						path: ':customerId/edit',
						element: <CustomerEdit />
					}
				]
			},
			{
				path: 'invoices',
				children: [
					{
						path: '',
						element: <PracticeInvoices />
					},
					{
						path: ':invoiceId',
						element: <InvoiceDetails />
					}
				]
			},
			{
				path: 'all-invoices',
				element: <ConsolidatorInvoices />
			},
			{
				path: 'terminal-orders',
				children: [
					{
						path: '',
						element: <TerminalOrders />
					}
				]
			},
			{
				path: 'readers-reporting',
				children: [
					{
						path: '',
						element: <ReadersReporting />
					}
				]
			},
			{
				path: 'postcard-file',
				children: [
					{
						path: '',
						element: <PostcardFiles />
					},
					{
						path: ':fileId',
						element: <PostcardFileItems />
					}
				]
			},
			{
				path: 'accounts',
				children: [
					{
						path: 'invitations',
						element: <AccountInvite />
					}
				]
			},
			{
				path: 'help-desk',
				element: <HelpDesk />
			},
			{
				path: 'monthly-report',
				element: <ConsolidatorMonthlyReport />
			},
			{
				path: 'installment-plans',
				element: <InstallmentPlans />
			},
			{
				path: 'past-due-plans',
				element: <PastDueInstallmentPlans />
			},
			{
				path: 'storedcard',
				element: <StoredCards />
			},
			{
				path: 'opt-outs',
				element: <OptOutsPage />
			},
			{
				path: 'custom-payouts',
				element: <CustomPayoutsPracticesList />
			},
			{
				path: 'practice-fee-settings',
				element: <PracticeFeeSettingsList />
			}
		]
	},
	{
		path: 'communication',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: 'reminders/:tab/',
				element: <Reminders />
			},
			{
				path: 'reminders/:tab/:clientId',
				element: <Reminders />
			},
			{
				path: 'one-time-email/:tab',
				element: <OneTimeEmail />
			},
			{
				path: 'appointment-requests',
				element: <AppointmentRequests />
			},
			{
				path: 'forms',
				element: <Forms />
			},
			{
				path: 'priority-call-list',
				element: <PriorityCallList />
			},
			{
				path: 'reputation-management',
				element: <ReputationManagement />
			},
			{
				path: 'opt-outs',
				element: <OptOuts />
			}
		]
	},
	{
		path: 'financing',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: 'get-started',
				element: (
					<FinancingDevGuard>
						<GetStarted />
					</FinancingDevGuard>
				)
			},
			{
				path: 'training',
				element: (
					<FinancingDevGuard>
						<Training />
					</FinancingDevGuard>
				)
			},
			{
				path: 'account/:tabParam?',
				element: (
					<FinancingDevGuard>
						<FinancingAccount />
					</FinancingDevGuard>
				)
			},
			{
				path: 'plans',
				element: (
					<FinancingDevGuard>
						<FinancingPlans />
					</FinancingDevGuard>
				)
			}
		]
	},
	{
		path: 'VendorNames',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <SpecialNames />
			}
		]
	},
	{
		path: 'LockboxChecks',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <LockboxChecks />
			}
		]
	},
	{
		path: 'settings',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: 'banking-info',
				element: <BankingInfo />
			},
			{
				path: 'required-info',
				element: <RequiredInfo />
			}
		]
	},
	{
		path: 'forms',
		element: <DynamicForm />,
		children: [
			{
				path: ':templateParam',
				element: <DynamicForm />
			},
			{
				path: ':templateParam/:conversationParam',
				element: <DynamicForm />
			}
		]
	},
	{
		path: 'signup',
		element: <PracticeSignUp />
	},
	{
		path: '*',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <GettingStarted />
			},
			{
				path: '401',
				element: <AuthorizationRequired />
			},
			{
				path: '404',
				element: <NotFound />
			},
			{
				path: '500',
				element: <ServerError />
			},
			{
				path: '*',
				element: <NotFound />
			}
		]
	}
]

export default routes
