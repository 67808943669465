import * as Yup from 'yup'
import { Formik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Button, FormHelperText, Grid2 as Grid, TextField, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { logError } from '../../../util/logger'
import { setIsAuthCheckLoading } from '../../../slices/auth'
import { signInWithEmailAndPassword } from '../../../lib/firebase'

const getLoginError = (message: string) => {
	if (
		message === 'Firebase: Error (auth/user-not-found).' ||
		message === 'Firebase: Error (auth/wrong-password).'
	) {
		return 'Your login credentials didn’t match our records. Please try again or reset your password if needed.'
	}
	return 'Something went wrong. Please try again later.'
}

const LoginFirebase = (props) => {
	const dispatch = useDispatch()
	return (
		<div {...props}>
			<Formik
				initialValues={{
					email: '',
					password: '',
					submit: null
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string().max(255).required('Username is required'),
					password: Yup.string().max(255).required('Password is required')
				})}
				onSubmit={async (values, { setStatus, setSubmitting, resetForm }) => {
					try {
						if (values.email.includes('@')) {
							await signInWithEmailAndPassword(values.email, values.password)
						} else {
							await signInWithEmailAndPassword(`${values.email}@scratchpay.care`, values.password)
						}
						dispatch(setIsAuthCheckLoading(true))
						setStatus({ success: true })
						setSubmitting(false)
					} catch (err: any) {
						dispatch(setIsAuthCheckLoading(false))
						logError(err)
						setStatus({ success: false })
						resetForm({
							values: { email: values.email, password: '', submit: null },
							errors: { submit: getLoginError(err.message) },
							touched: { email: true, password: true }
						})
						setSubmitting(false)
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form noValidate onSubmit={handleSubmit} {...props}>
						<Grid container spacing={3}>
							<Grid
								size={{
									xs: 12,
									sm: 12
								}}
							>
								<Typography sx={{ mb: -1 }} variant="subtitle2">
									Username:
								</Typography>
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									value={values.email}
									helperText={touched.email && errors.email}
									margin="normal"
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									variant="outlined"
								/>
							</Grid>
							<Grid
								size={{
									xs: 12,
									sm: 12
								}}
							>
								<Typography sx={{ mb: -1 }} variant="subtitle2">
									Password:
								</Typography>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									margin="normal"
									name="password"
									value={values.password}
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									variant="outlined"
								/>
							</Grid>
						</Grid>
						{errors.submit && errors.submit !== 'The email address is badly formatted.' && (
							<Box sx={{ mt: 3 }}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}
						<Box sx={{ mt: 4 }}>
							<Button
								color="primary"
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
								data-cy="login-button"
							>
								Log In
								{isSubmitting ? <CircularProgress sx={{ p: 1.5 }} /> : ''}
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</div>
	)
}

export default LoginFirebase
