import { getEnvVarAsBoolean } from './util/environment.helper'
import packageInfo from '../package.json'
import { ENV_PRODUCTION } from './constants'

export const amplifyConfig = {
	aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
	aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
	aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
	aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
	aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
}

export const auth0Config = {
	client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN
}

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	storageApi: process.env.REACT_APP_FIREBASE_STORAGE_API,
	measurmentId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
}

export const mixpanelConfig = {
	token: process.env.REACT_APP_MIXPANEL_TOKEN
}

export const twilioConfig = {
	accountSid: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
	authToken: process.env.TWILIO_AUTH_TOKEN,
	phoneNumbers: {
		US: process.env.REACT_APP_TWILIO_US_PHONE_NUMBER,
		CA: process.env.REACT_APP_TWILIO_CA_PHONE_NUMBER
	}
}

export const SCRATCHPAY_CASH_BACKEND_CONFIG = {
	baseURL: process.env.REACT_APP_CHECKOUT_WEB_BASE_URL
}

export const CHECKOUT_PAYMENT_SERVICE_CONFIG = {
	baseURL: process.env.REACT_APP_CHECKOUT_PAYMENT_SERVICE_BASE_URL
}

export const GOOGLE_MAPS_CONFIG = {
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
}

export const CALENDLY_LINKS = {
	payments: process.env.REACT_APP_CALENDLY_LINK_PAYMENTS,
	lending: process.env.REACT_APP_CALENDLY_LINK_LENDING
}

export const ENV = process.env.REACT_APP_DEPLOYMENT_ENV

export const UNIFIED_CONFIG = {
	enabled: process.env.REACT_APP_UNIFIED_ENABLED === 'true',
	emailDomains: (process.env.REACT_APP_UNIFIED_DOMAINS ?? '').split(','),
	baseURL: process.env.REACT_APP_UNIFIED_DASH_BASE_URL || 'dashboard.scratchpay.com'
}

export const SENTRY_CONFIG = {
	dsn: process.env.REACT_APP_SENTRY_DSN
}

export const AG_GRID_LICENSE = process.env.REACT_APP_AG_GRID_LICENSE

export const MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE

export const FRONT_APP_CHAT_ID = process.env.REACT_APP_FRONT_APP_CHAT_ID

export const CURRENT_VERSION = packageInfo.version

export const FRILL_WIDGET_ID = process.env.REACT_APP_FRILL_WIDGET_ID

export const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL

export const PERSONA_CONFIG = {
	environment: ENV === ENV_PRODUCTION ? 'production' : 'sandbox'
}

export const PLAID_CONFIG = {
	isEnabled: getEnvVarAsBoolean(process.env.REACT_APP_PLAID_ENABLED)
}

export const contentfulConfig = {
	spaceId: process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? '',
	accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? '',
	environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT ?? 'master'
}

export const STRIPE = {
	publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''
}

export const MAVEN_AGI_CHAT_CONFIG = {
	organizationId: process.env.REACT_APP_MAVEN_AGI_ORGANIZATION_ID ?? '',
	agentId: process.env.REACT_APP_MAVEN_AGI_AGENT_ID ?? ''
}
