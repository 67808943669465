import { createClient } from 'contentful'
import { contentfulConfig } from '../config'
import { ButtonActionType as StepTileButtonActionType } from '../components/common/StepTile'

type BaseContent = {
	[key: string]: any
}

interface FlagObject {
	[key: string]: boolean
}

interface HasOrder {
	order: number
}

interface HasFlag {
	flag: string
}

const fetchContentful = async <T extends BaseContent>(
	contentType: string,
	flagObj: FlagObject = {}
): Promise<T[]> => {
	const content = await getContent(contentType)

	const items: T[] = []
	content.items.forEach((item) => {
		const entry = item.fields as T

		let shouldPush = true
		if (hasFlagField(entry)) {
			const fetchedFlag: string | undefined = entry.flag

			// Check if the fetched flag is defined and exists in the argument flagObj and is set to true
			if (fetchedFlag !== undefined && fetchedFlag in flagObj && flagObj[fetchedFlag] === true) {
				shouldPush = false
			}
		}
		if (shouldPush) {
			items.push(entry)
		}
	})

	if (items.length > 0 && hasOrderField(items[0])) {
		;(items as (T & HasOrder)[]).sort((a, b) => a.order - b.order)
	}

	return items
}

export const getContent = async (contentType) => {
	const client = createClient({
		space: contentfulConfig.spaceId,
		accessToken: contentfulConfig.accessToken,
		environment: contentfulConfig.environment
	})

	return client.getEntries({ content_type: contentType })
}

// Check if an item has an order field
const hasOrderField = <T extends BaseContent>(item: T): item is T & HasOrder =>
	(item as T & HasOrder).order !== undefined

// Check if an item has a flag field
const hasFlagField = <T extends BaseContent>(item: T): item is T & HasFlag =>
	(item as T & HasFlag).flag !== undefined

type ContentfulMedia = {
	fields: {
		file: {
			url: string
		}
	}
}

type GetStartedContent = {
	title: string
	content: string
	buttonCta: string
	buttonLink: string
	flag: string
	thumbnail: ContentfulMedia
	duration: string
	order: number
}

export const getStartedContent = async (flags): Promise<GetStartedContent[]> =>
	fetchContentful<GetStartedContent>('getStarted', flags)

type GetTrainingContent = {
	title: string
	content: string
	buttonCta: string
	buttonLink: string
	flag: string
	thumbnail: ContentfulMedia
	duration: string
	order: number
}

export const getTrainingContent = async (flags): Promise<GetTrainingContent[]> =>
	fetchContentful<GetTrainingContent>('training', flags)

type GetMarketingBannerContent = {
	title: string
	content: string
	buttonCta: string
	buttonLink: string
	thumbnail: ContentfulMedia
}

export const getMarketingBannerContent = async (): Promise<GetMarketingBannerContent[]> =>
	fetchContentful<GetMarketingBannerContent>('marketingBanner')

type GetMarketingContent = {
	title: string
	content: string
	buttonCta: string
	buttonLink: string
	flag: string
	thumbnail: ContentfulMedia
	order: number
	tooltip: string
}

export const getMarketingContent = async (flags): Promise<GetMarketingContent[]> =>
	fetchContentful<GetMarketingContent>('marketingMaterials', flags)

type GetClinicLinkContent = {
	title: string
	content: string
	buttonCta: string
	buttonLink: string
	buttonType: StepTileButtonActionType
	step: string
	order: number
	showClinicLink: boolean
}

export const getClinicLinkContent = async (flags): Promise<GetClinicLinkContent[]> =>
	fetchContentful<GetClinicLinkContent>('scratchPayBanner', flags)

export type GetAdsContent = {
	title: string
	image: ContentfulMedia
	link: string
	order: number
	flag: string
	metadata: Record<string, any>
}

export const getAdsContent = async (flags): Promise<GetAdsContent[]> =>
	fetchContentful<GetAdsContent>('sideNavAds', flags)
