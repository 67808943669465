import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'nprogress/nprogress.css'
import './i18n/i18n'
import { StrictMode } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { LicenseInfo } from '@mui/x-license'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import ChatClient from 'util/ChatClient'
import ErrorBoundary from 'pages/errors/ErrorBoundary'
import App from './App'
import { PracticeProvider } from './contexts/PracticeContext'
import { SettingsProvider } from './contexts/SettingsContext'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { initSentry } from './util/sentry'
import CustomizedLabels from './util/CustomizedLabels'
import { MUI_LICENSE } from './config'

LicenseInfo.setLicenseKey(
	MUI_LICENSE ??
		'a1d47340217e512caf32362943f0106eTz04NzY0MixFPTE3NDM2NDYxODUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string, {
	betas: ['us_bank_account_beta_2'],
	apiVersion: '2020-08-27; us_bank_account_beta=v2'
})

initSentry()
CustomizedLabels.init()
ChatClient.init()

const rootElement = document.getElementById('root') as Element
const root = ReactDOMClient.createRoot(rootElement)

root.render(
	<StrictMode>
		<StyledEngineProvider injectFirst>
			<ErrorBoundary>
				<HelmetProvider>
					<ReduxProvider store={store}>
						<PracticeProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<SettingsProvider>
									<BrowserRouter>
										<Elements stripe={stripePromise}>
											<App />
										</Elements>
									</BrowserRouter>
								</SettingsProvider>
							</LocalizationProvider>
						</PracticeProvider>
					</ReduxProvider>
				</HelmetProvider>
			</ErrorBoundary>
		</StyledEngineProvider>
	</StrictMode>
)

// If you want to enable client cache, register instead.
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined)
